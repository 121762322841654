import {
  BaseQueryFn,
  createApi,
  FetchArgs,
} from '@reduxjs/toolkit/query/react';
import {
  IParameters,
  IOptionHabitatApi,
  IEmprunteurRequest,
  IOffreCommercialeRequest,
  IInsuranceRequest,
  IParametresRequest,
  ICoEmprunteurRequest,
  IUploadDocumentsResponse,
  IModifyBorrowerRequest,
  IContratElectronique,
  IContratPapier,
  IBankInformationsRequest,
  ISouscriptionRequest,
  IDocumentsUploaded,
  CodesErreurValidationSiren,
  ILogsRequest,
  ICoEmprunteur,
  IBankInformations,
  IEmprunteur,
  IEvenementSwitchMobile,
  IConsultSwitchMobileRequest,
  IAjoutSwitchMobileRequest,
} from './souscriptionApiTypes';
import {
  Insurance,
  ScoreLightType,
  IRecapitulatif,
  CodesErreurRib,
  CodesErreurTarification,
} from '@types';
import { AnalyseDocument } from 'types/document';
import { EndpointBuilder } from '@reduxjs/toolkit/dist/query/endpointDefinitions';
import { getDynamicQueriesWithRetries, IEndPoints } from '../rtkQueryUtils';

const TIMEOUT = Number(process.env.REACT_APP_TIMEOUT) ?? 30000;

const sendModifyBorrowerBuilderMutation = (
  builder: EndpointBuilder<BaseQueryFn, never, 'souscriptionApi'>
) =>
  builder.mutation<
    void,
    {
      numeroSouscription: string;
      arg: {
        etape: string;
        typeEmprunteur: string;
      };
      modifyBorrowerRequest: IModifyBorrowerRequest;
    }
  >({
    query: ({ numeroSouscription, arg, modifyBorrowerRequest }) => ({
      url: `souscriptions/${numeroSouscription}/emprunteur`,
      params: { ...arg },
      method: 'PATCH',
      credentials: 'include',
      body: {
        ...modifyBorrowerRequest,
      },
    }),
  });

const souscriptionApiSlice = createApi({
  reducerPath: 'souscriptionApi',
  baseQuery: getDynamicQueriesWithRetries(IEndPoints.souscription),
  endpoints: (builder) => ({
    getParameters: builder.query<IParameters, IParametresRequest>({
      query: ({
        numeroSouscription,
        apimAccessToken,
        authInitToken,
      }): FetchArgs => {
        const theQuery: { url: string; headers?: any; credentials: any } = {
          url: `souscriptions/${numeroSouscription}/parametres`,
          credentials: 'include',
        };
        if (apimAccessToken) {
          theQuery.headers = {
            'apim-access-token': apimAccessToken,
          };
        }
        if (authInitToken) {
          theQuery.headers = {
            'auth-init-token': authInitToken,
          };
        }
        return theQuery;
      },
    }),
    getAuthCheck: builder.query<any, void>({
      query: (): FetchArgs => {
        return {
          url: `ping`,
          credentials: 'include',
        };
      },
    }),
    getDocumentsUploaded: builder.query<IDocumentsUploaded[], string>({
      query: (numeroSouscription) => {
        return {
          url: `souscriptions/${numeroSouscription}/documents/uploaded`,
          credentials: 'include',
        };
      },
    }),
    getCountries: builder.query<IOptionHabitatApi[], void>({
      query: () => {
        return {
          url: `localites/pays`,
          credentials: 'include',
        };
      },
    }),
    getDepartments: builder.query<IOptionHabitatApi[], void>({
      query: () => {
        return {
          url: `localites/departements`,
          credentials: 'include',
        };
      },
    }),
    getDepartmentsByCity: builder.query<IOptionHabitatApi[], string>({
      query: (ville) => {
        return {
          url: `localites/departements/${ville}`,
          credentials: 'include',
        };
      },
    }),
    getCities: builder.query<IOptionHabitatApi[], string>({
      query: (numeroDepartement) => {
        return {
          url: `localites/departements/${numeroDepartement}/villes`,
          credentials: 'include',
        };
      },
    }),
    getCitiesByZipCd: builder.query<IOptionHabitatApi[], string>({
      query: (zipCd) => {
        return {
          url: `localites/villes/${zipCd}`,
          credentials: 'include',
        };
      },
    }),
    getInsurancesBorrower: builder.query<Insurance[], string>({
      query: (numeroSouscription) => {
        return {
          url: `souscriptions/${numeroSouscription}/emprunteur-principal/assurances`,
          credentials: 'include',
        };
      },
    }),
    getInsurancesCoBorrower: builder.query<Insurance[], string>({
      query: (numeroSouscription) => {
        return {
          url: `souscriptions/${numeroSouscription}/co-emprunteur/assurances`,
          credentials: 'include',
        };
      },
    }),
    getRecapitulatif: builder.query<IRecapitulatif, string>({
      query: (numeroSouscription) => {
        return {
          url: `souscriptions/${numeroSouscription}/recapitulatif_credit`,
          credentials: 'include',
        };
      },
    }),
    getIdDebranchementQR: builder.query<string, string>({
      query: (numero_souscription) => ({
        url: `souscriptions/${numero_souscription}/qrcode`,
        credentials: 'include',
        responseHandler: 'text',
      }),
    }),
    getConsultSwitchMobile: builder.query<
      IEvenementSwitchMobile[],
      IConsultSwitchMobileRequest
    >({
      query: ({ numero_souscription, body }) => ({
        url: `souscriptions/${numero_souscription}/switch/consult`,
        credentials: 'include',
        params: { ...body },
      }),
    }),
    sendAjoutEvenementSwitchMobile: builder.mutation<
      void,
      IAjoutSwitchMobileRequest
    >({
      query: ({ numero_souscription, body }) => ({
        url: `souscriptions/${numero_souscription}/switch/ajout`,
        method: 'POST',
        credentials: 'include',
        params: { ...body },
      }),
    }),
    sendOffreCommerciale: builder.mutation<void, IOffreCommercialeRequest>({
      query: ({ numero_souscription, offre_commerciale }) => ({
        url: `souscriptions/${numero_souscription}/offre-commerciale`,
        method: 'PATCH',
        credentials: 'include',
        body: {
          ...offre_commerciale,
        },
      }),
    }),
    sendLogs: builder.mutation<void, ILogsRequest>({
      query: (logs) => ({
        url: `souscriptions/logs-front`,
        method: 'PATCH',
        credentials: 'include',
        body: {
          ...logs,
        },
      }),
    }),
    sendEmprunteur: builder.mutation<void, IEmprunteurRequest>({
      query: ({ numero_souscription, emprunteur }) => ({
        url: `souscriptions/${numero_souscription}/emprunteur-principal`,
        method: 'PATCH',
        credentials: 'include',
        body: {
          ...emprunteur,
        },
      }),
    }),
    getEmprunteurInformations: builder.query<IEmprunteur, string>({
      query: (numeroSouscription): FetchArgs => {
        return {
          url: `souscriptions/${numeroSouscription}/emprunteur-principal`,
          credentials: 'include',
        };
      },
    }),
    getCoEmprunteurInformations: builder.query<ICoEmprunteur, string>({
      query: (numeroSouscription): FetchArgs => {
        return {
          url: `souscriptions/${numeroSouscription}/co-emprunteur`,
          credentials: 'include',
        };
      },
    }),
    getBankInformations: builder.query<IBankInformations, string>({
      query: (numeroSouscription): FetchArgs => {
        return {
          url: `souscriptions/${numeroSouscription}/informations-bancaires`,
          credentials: 'include',
        };
      },
    }),
    sendInsuranceBorrower: builder.mutation<void, IInsuranceRequest>({
      query: ({ insurance, numero_souscription }) => ({
        url: `souscriptions/${numero_souscription}/emprunteur-principal/assurance`,
        method: 'PATCH',
        credentials: 'include',
        body: {
          ...insurance,
        },
      }),
    }),
    sendInsuranceCoBorrower: builder.mutation<void, IInsuranceRequest>({
      query: ({ insurance, numero_souscription }) => ({
        url: `souscriptions/${numero_souscription}/co-emprunteur/assurance`,
        method: 'PATCH',
        credentials: 'include',
        body: {
          ...insurance,
        },
      }),
    }),
    sendContratPapier: builder.mutation<
      IContratPapier,
      { numero_souscription: string }
    >({
      query: ({ numero_souscription }) => ({
        url: `souscriptions/${numero_souscription}/contrat/papier`,
        method: 'POST',
        credentials: 'include',
      }),
    }),
    sendContratElectronique: builder.mutation<
      IContratElectronique,
      { numero_souscription: string; urlRetour?: string }
    >({
      query: ({ numero_souscription, urlRetour }) => ({
        url: `souscriptions/${numero_souscription}/contrat/electronique`,
        method: 'POST',
        credentials: 'include',
        params: { urlRetour },
      }),
    }),
    getScore: builder.query<
      { score: ScoreLightType },
      { numeroSouscription: string; scoreModeFormation?: string }
    >({
      query: ({ numeroSouscription, scoreModeFormation }) => {
        return {
          url: `souscriptions/${numeroSouscription}/score`,
          credentials: 'include',
          params: { scoreModeFormation: scoreModeFormation },
        };
      },
    }),
    sendCoEmprunteur: builder.mutation<void, ICoEmprunteurRequest>({
      query: ({ numero_souscription, coemprunteur }) => ({
        url: `souscriptions/${numero_souscription}/co-emprunteur`,
        method: 'PATCH',
        credentials: 'include',
        body: {
          ...coemprunteur,
        },
      }),
    }),
    sendDocument: builder.mutation<
      IUploadDocumentsResponse,
      {
        numero_souscription: string;
        arg: {
          soustypePiece: string;
          typeEmprunteur: string;
          typePiece: string;
        };
        formData: FormData;
      }
    >({
      query: ({ numero_souscription, arg, formData }) => ({
        url: `souscriptions/${numero_souscription}/upload`,
        method: 'POST',
        credentials: 'include',
        params: { ...arg },
        body: formData,
      }),
    }),
    sendAnalyseDocument: builder.mutation<
      AnalyseDocument,
      {
        numeroSouscription: string;
        arg: {
          soustypePiece: string;
          typeEmprunteur: string;
          typePiece: string;
        };
      }
    >({
      query: ({ numeroSouscription, arg }) => ({
        url: `souscriptions/${numeroSouscription}/analyse-document`,
        params: { ...arg },
        method: 'POST',
        credentials: 'include',
        timeout: TIMEOUT,
      }),
    }),
    sendDeleteDocument: builder.mutation<
      void,
      {
        numeroSouscription: string;
        arg: {
          nomFichier: string;
          typeEmprunteur: string;
          typePiece: string;
        };
      }
    >({
      query: ({ numeroSouscription, arg }) => ({
        url: `souscriptions/${numeroSouscription}/supprimer-document`,
        params: { ...arg },
        method: 'POST',
        credentials: 'include',
      }),
    }),
    deleteDocumentCancel: builder.mutation<
      void,
      {
        numeroSouscription: string;
        arg: {
          nomFichier: string;
          typeEmprunteur: string;
          typePiece: string;
        };
      }
    >({
      query: ({ numeroSouscription, arg }) => ({
        url: `souscriptions/${numeroSouscription}/supprimer-document-annule`,
        params: { ...arg },
        method: 'POST',
        credentials: 'include',
      }),
    }),
    sendModifyBorrower: sendModifyBorrowerBuilderMutation(builder),
    sendModifyBorrowerWithoutError: sendModifyBorrowerBuilderMutation(builder),
    sendBankInformations: builder.mutation<void, IBankInformationsRequest>({
      query: ({ numero_souscription, isOcr, bankInformations }) => ({
        url: `souscriptions/${numero_souscription}/informations-bancaires`,
        method: 'PATCH',
        credentials: 'include',
        params: { isOcr: isOcr },
        body: {
          ...bankInformations,
        },
      }),
    }),
    sendCreateSouscription: builder.mutation<
      { apply_url: string },
      { souscriptionRequest: ISouscriptionRequest }
    >({
      query: ({ souscriptionRequest }) => ({
        url: `souscriptions`,
        method: 'POST',
        credentials: 'include',
        body: { ...souscriptionRequest },
      }),
    }),
    getValidationSirenNumber: builder.query<void, string>({
      query: (numeroSiren) => {
        return {
          url: `controle-numero-siren/${numeroSiren}`,
          credentials: 'include',
        };
      },
    }),
  }),
});
const endpointShouldGenerateError = (endpoint: string): boolean => {
  return ![
    'getCitiesByZipCd',
    'getDepartmentsByCity',
    'sendAnalyseDocument',
    'sendDocument',
    'sendContratElectronique',
    'sendModifyBorrowerWithoutError',
    'sendLogs',
    'getEmprunteurInformations',
    'getCoEmprunteurInformations',
    'getBankInformations',
    'sendBankInformations',
    'getRecapitulatif',
  ].includes(endpoint);
};

const endpointWithCodeShouldGenerateError = (
  endpoint: string,
  code?: string
): boolean => {
  return ![
    {
      endpoint: 'sendOffreCommerciale',
      code: [CodesErreurTarification.CHRONIQUE_NON_TROUVEE.toString()],
    },
    {
      endpoint: 'getValidationSirenNumber',
      code: [
        CodesErreurValidationSiren.FORMAT_NUMERO_SIREN_KO.toString(),
        CodesErreurValidationSiren.NUMERO_SIREN_INVALIDE.toString(),
        CodesErreurValidationSiren.NUMERO_SIREN_VIDE.toString(),
      ],
    },
    {
      endpoint: 'getConsultSwitchMobile',
      code: ['RECUPERATION_SWITCH_DEVICE_TIMEOUT'],
    },
    {
      endpoint: 'deleteDocumentCancel',
      code: ['FICHIER_NON_TROUVE'],
    },
  ].find(
    (error) => error.endpoint === endpoint && error.code.includes(code ?? '')
  );
};

export const souscriptionEndpointsToNotCheck = [
  endpointShouldGenerateError,
  endpointWithCodeShouldGenerateError,
];

export default souscriptionApiSlice;
export const {
  useLazyGetParametersQuery,
  useLazyGetCountriesQuery,
  useLazyGetDepartmentsQuery,
  useLazyGetDepartmentsByCityQuery,
  useLazyGetCitiesQuery,
  useLazyGetCitiesByZipCdQuery,
  useLazyGetRecapitulatifQuery,
  useLazyGetDocumentsUploadedQuery,
  useSendOffreCommercialeMutation,
  useSendLogsMutation,
  useSendEmprunteurMutation,
  useSendInsuranceBorrowerMutation,
  useLazyGetInsurancesBorrowerQuery,
  useSendInsuranceCoBorrowerMutation,
  useLazyGetInsurancesCoBorrowerQuery,
  useSendContratPapierMutation,
  useSendContratElectroniqueMutation,
  useLazyGetScoreQuery,
  useSendCoEmprunteurMutation,
  useSendDocumentMutation,
  useSendAnalyseDocumentMutation,
  useSendDeleteDocumentMutation,
  useSendModifyBorrowerMutation,
  useSendModifyBorrowerWithoutErrorMutation,
  useDeleteDocumentCancelMutation,
  useSendBankInformationsMutation,
  useSendCreateSouscriptionMutation,
  useLazyGetValidationSirenNumberQuery,
  useGetAuthCheckQuery,
  useLazyGetAuthCheckQuery,
  useLazyGetEmprunteurInformationsQuery,
  useLazyGetCoEmprunteurInformationsQuery,
  useLazyGetBankInformationsQuery,
  useLazyGetIdDebranchementQRQuery,
  useLazyGetConsultSwitchMobileQuery,
  useSendAjoutEvenementSwitchMobileMutation,
} = souscriptionApiSlice;
export const souscriptionApiReducer = souscriptionApiSlice.reducer;
export const souscriptionApiMiddleware = souscriptionApiSlice.middleware;
