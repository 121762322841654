import {
  selectConfiguration,
  selectInsurances,
  selectOffer,
  selectPerson,
  updateConfiguration,
  updateInsurances,
  updateInsuranceSelectedCoemprunteur,
  updateInsuranceSelectedEmprunteur,
  updateOffer,
  updatePersonCoEmprunteur,
  updatePersonEmprunteur,
  useAppDispatch,
  useAppSelector,
  useLazyGetScoreQuery,
} from '@store';
import { ButtonsFooter, Icons, Modal } from '@components';
import { useBorrowers, usePersistingState } from '@hooks';
import {
  StyledCentering,
  StyledDescription,
  StyledTitle,
  StyledWrapperSummary,
} from './styles';
import * as messages from './messages';
import {
  IInsuranceType,
  Insurance,
  RoleCd,
  ScoreLightType,
  IPerson,
} from '@types';
import SummaryCard from './SummaryCard';
import { useEffect, useState } from 'react';
import { FormationModeScoreChoice } from '@containers';
import { useLazyGetRecapitulatifQuery } from 'store/souscriptionApi/souscriptionApiSlice';
import { NO_INSURANCE_KEY } from 'containers/Insurance/constant';
import { useLocation } from 'react-router-dom';

const Summary: React.FC = () => {
  const { scoreLight, souscriptionId, isInFormationMode } =
    useAppSelector(selectConfiguration);
  const offer = useAppSelector(selectOffer);
  const { state: stateLocation } = useLocation();
  const [triggerGetScore, resultGetScore] = useLazyGetScoreQuery();
  const [triggerGetRecapitulatif, resultGetRecapitulatif] =
    useLazyGetRecapitulatifQuery();
  const [isShowingDecision, setIsShowingDecision] = usePersistingState(
    'recap-show-decision',
    false
  );

  const { emprunteur, coemprunteur } = useAppSelector(selectPerson);
  const {
    emprunteur: assurancesEmprunteur,
    coemprunteur: assurancesCoemprunteur,
    synthese: syntheseAssurances,
  } = useAppSelector(selectInsurances);

  const getAssuranceSelected = (assurancePerson: IInsuranceType) =>
    (assurancePerson?.insurances ?? []).find(
      (assurance) =>
        assurance?.code_bareme === assurancePerson?.insuranceCodeBaremeSelected
    ) ?? ({} as Insurance);
  const [assuranceEmprunteurSelected, setEmprunteurSelected] = useState(
    getAssuranceSelected(assurancesEmprunteur ?? {})
  );
  const [assuranceCoemprunteurSelected, setCoemprunteurSelected] = useState(
    getAssuranceSelected(assurancesCoemprunteur ?? {})
  );

  const getMontantMensualite = (assurances: IInsuranceType | undefined) => {
    const insurance = (assurances?.insurances ?? [])[0]?.mensualite_credit
      ? (assurances?.insurances ?? [])[0]
      : (assurances?.insurances ?? [])[1];
    return (
      (+insurance?.mensualite_credit ?? 0) - (+insurance?.cout_mensuel ?? 0)
    );
  };

  const [isModalFormationModeActive, setIsModalFormationModeActive] =
    useState(false);

  const { hasCoborrower } = useBorrowers();
  const dispatch = useAppDispatch();
  const person = useAppSelector(selectPerson);

  useEffect(() => {
    if (isShowingDecision || !stateLocation?.isNavButton) {
      return;
    }
    triggerGetRecapitulatif(souscriptionId ?? '');
    setEmprunteurSelected(getAssuranceSelected(assurancesEmprunteur ?? {}));
    setCoemprunteurSelected(getAssuranceSelected(assurancesCoemprunteur ?? {}));
  }, [, isShowingDecision]);

  useEffect(() => {
    if (!resultGetRecapitulatif?.data) {
      return;
    }
    const data = resultGetRecapitulatif.data;
    dispatch(
      updatePersonEmprunteur({
        identity: {
          ...person?.emprunteur?.identity,
          firstName: data.emprunteur?.prenom,
          lastName: data.emprunteur?.nom,
        },
      })
    );
    if (data.coemprunteur) {
      dispatch(
        updatePersonCoEmprunteur({
          identity: {
            ...person?.coemprunteur?.identity,
            firstName: data.coemprunteur?.prenom,
            lastName: data.coemprunteur?.nom,
          },
        })
      );
    }
    dispatch(
      updateOffer({
        inputPurchaseAmount: data.offre_financement?.montant_achat,
        inputDepositAmount: data.offre_financement?.montant_apport,
        inputLoanAmount: data.offre_financement?.montant_credit,
        taeg: data.offre_financement?.taeg,
        inputTermNb: data.offre_financement?.nombre_mensualites,
        inputDeferment: data.offre_financement?.report,
        interets: data.offre_financement?.interets,
      })
    );

    dispatch(
      updateInsuranceSelectedEmprunteur({
        code:
          data.assurance_emprunteur?.code_option_assurance !== '0'
            ? data.assurance_emprunteur?.code_option_assurance
            : NO_INSURANCE_KEY,
        labelRecap: data.assurance_emprunteur?.option_assurance,
        cout_mensuel: data.assurance_emprunteur?.cout_mensuel_assurance + '',
        cout_total: data.assurance_emprunteur?.cout_total_assurance + '',
        mensualite_avec_assurance:
          data.assurance_emprunteur?.mensualite_avec_assurance,
      })
    );
    if (data.coemprunteur && data.assurance_coemprunteur) {
      dispatch(
        updateInsuranceSelectedCoemprunteur({
          code:
            data.assurance_coemprunteur?.code_option_assurance !== '0'
              ? data.assurance_coemprunteur?.code_option_assurance
              : NO_INSURANCE_KEY,
          labelRecap: data.assurance_coemprunteur?.option_assurance,
          cout_mensuel:
            data.assurance_coemprunteur?.cout_mensuel_assurance + '',
          cout_total: data.assurance_coemprunteur?.cout_total_assurance + '',
          mensualite_avec_assurance:
            data.assurance_coemprunteur?.mensualite_avec_assurance,
        })
      );
    }
    dispatch(
      updateInsurances({
        synthese: data.synthese_assurances
          ? {
              mensualiteAvecAssurances:
                data.synthese_assurances?.mensualite_avec_assurances ?? 0,
              coutMensuelAssurances:
                data.synthese_assurances?.cout_mensuel_assurances ?? 0,
              coutTotalAssurances:
                data.synthese_assurances?.cout_total_assurances ?? 0,
              taeaGlobal: data.synthese_assurances?.taea_global ?? 0,
            }
          : {},
      })
    );
  }, [resultGetRecapitulatif?.data]);

  useEffect(() => {
    if (!assurancesEmprunteur?.insurances) {
      return;
    }
    setEmprunteurSelected(getAssuranceSelected(assurancesEmprunteur ?? {}));
  }, [assurancesEmprunteur?.insurances]);

  useEffect(() => {
    if (!assurancesCoemprunteur?.insurances) {
      return;
    }
    setCoemprunteurSelected(getAssuranceSelected(assurancesCoemprunteur ?? {}));
  }, [assurancesCoemprunteur?.insurances]);

  useEffect(() => {
    if (!resultGetScore?.data) {
      return;
    }
    if (!resultGetScore.isUninitialized && !isInFormationMode) {
      dispatch(
        updateConfiguration({
          scoreLight: resultGetScore.data?.score,
        })
      );
    }
    setIsShowingDecision(true);
  }, [resultGetScore?.data]);

  const onSubmit = () => {
    if (isInFormationMode) {
      setIsModalFormationModeActive(!isModalFormationModeActive);
    } else {
      triggerGetScore({
        numeroSouscription: souscriptionId ?? '',
        scoreModeFormation: undefined,
      });
    }
  };

  const actionChoseFormationModeScore = (chosenScore?: ScoreLightType) => {
    setIsModalFormationModeActive(false);
    triggerGetScore({
      numeroSouscription: souscriptionId ?? '',
      scoreModeFormation: chosenScore ?? scoreLight ?? undefined,
    });
  };

  const getTaux = (taux: number | string = ' - ') =>
    ((taux ?? '') + '').replaceAll('.', ',') + '%';

  const getMontant = (montant?: number | string) => {
    if (
      (!montant && montant != 0) ||
      (typeof montant === 'string' && montant?.length === 0)
    ) {
      return ' - ';
    }
    return (
      Number(montant ?? '0')
        .toFixed(2)
        .replaceAll('.', ',') + '€'
    );
  };

  const getIsLoading = (value?: number | string) => {
    if (!value) {
      return (
        resultGetRecapitulatif?.isLoading || resultGetRecapitulatif?.isFetching
      );
    }
    return (
      (resultGetRecapitulatif?.isLoading ||
        resultGetRecapitulatif?.isFetching) &&
      !value &&
      value !== 0 &&
      value !== ''
    );
  };

  const getIdentity = (person: IPerson, role: RoleCd) => [
    {
      title: messages.NOM_PERSONNE,
      fieldName: 'nom_' + role,
      description: person?.identity?.lastName ?? '',
      isLoading: getIsLoading(person?.identity?.lastName),
    },
    {
      title: messages.PRENOM_PERSONNE,
      fieldName: 'prenom_' + role,
      description: person?.identity?.firstName ?? '',
      isLoading: getIsLoading(person?.identity?.firstName),
    },
  ];

  const offreFinancement = [
    {
      title: messages.CATEGORIE_TRAVAUX,
      fieldName: 'categorie_travaux',
      description: offer.materialCategoryLabel + '' ?? '',
      isLoading: getIsLoading(offer.materialCategoryLabel),
    },
    {
      title: messages.MONTANT_ACHAT,
      fieldName: 'montant_achat',
      description: getMontant(offer.inputPurchaseAmount),
      isLoading: getIsLoading(offer.inputPurchaseAmount),
    },
    {
      title: messages.MONTANT_APPORT,
      fieldName: 'montant_apport',
      description: getMontant(offer.inputDepositAmount),
      isLoading: getIsLoading(offer.inputDepositAmount),
    },
    {
      title: messages.MONTANT_CREDIT,
      fieldName: 'montant_credit',
      description: getMontant(offer.inputLoanAmount),
      isLoading: getIsLoading(offer.inputLoanAmount),
    },
    {
      title: messages.TAEG,
      fieldName: 'taeg',
      description: getTaux(offer?.taeg) ?? 0,
      isLoading: getIsLoading(),
    },
    {
      title: messages.MENSUALITE,
      fieldName: 'mensualite',
      description: getMontant(getMontantMensualite(assurancesEmprunteur)),
      isLoading: getIsLoading(assuranceEmprunteurSelected?.mensualite_credit),
    },
    {
      title: messages.NOMBRE_MENSUALITES,
      fieldName: 'nombre_mensualite',
      description: offer.inputTermNb + '' ?? '',
      isLoading: getIsLoading(offer.inputTermNb),
    },
  ];
  if (offer?.interets) {
    offreFinancement.push({
      title: messages.INTERETS,
      fieldName: 'interets',
      description: getMontant(offer?.interets),
      isLoading: getIsLoading(),
    });
  }
  offreFinancement.push({
    title: messages.REPORT,
    fieldName: 'report',
    description: (offer.inputDeferment ?? '0') + messages.REPORT_UNITES,
    isLoading: getIsLoading(offer.inputDeferment),
  });

  const synthese = [
    {
      title: messages.MENSUALITE_SYNTHESE,
      fieldName: 'mensualite_synthese',
      description: getMontant(
        syntheseAssurances?.mensualiteAvecAssurances + ''
      ),
      isLoading: getIsLoading(),
    },
    {
      title: messages.COUT_MENSUEL_ASSURANCES_SYNTHESE,
      fieldName: 'cout_mensuel_synthese',
      description: getMontant(syntheseAssurances?.coutMensuelAssurances + ''),
      isLoading: getIsLoading(),
    },
    {
      title: messages.COUT_TOTAL_ASSURANCES_SYNTHESE,
      fieldName: 'cout_total_synthese',
      description: getMontant(syntheseAssurances?.coutTotalAssurances + ''),
      isLoading: getIsLoading(),
    },
    {
      title: messages.TAEA_SYNTHESE,
      fieldName: 'taea_synthese',
      description: getTaux(syntheseAssurances?.taeaGlobal + ''),
      isLoading: getIsLoading(),
    },
  ];

  const getAssurance = (assuranceSelected: Insurance, role: RoleCd) => [
    {
      title: messages.COUT_MENSUEL,
      fieldName: 'cout_mensuel_' + role,
      description: getMontant(assuranceSelected.cout_mensuel),
      isLoading: getIsLoading(assuranceSelected.cout_mensuel),
    },
    {
      title: messages.COUT_TOTAL,
      fieldName: 'cout_total_' + role,
      description: getMontant(assuranceSelected.cout_total),
      isLoading: getIsLoading(),
    },
    {
      title: messages.TAEA,
      fieldName: 'taea_' + role,
      description: getTaux(assuranceSelected?.taea ?? 0),
      isLoading: getIsLoading(),
    },
    {
      title:
        role === RoleCd.BORROWER
          ? messages.MENSUALITE_TOTALE_EMPRUNTEUR
          : messages.MENSUALITE_TOTALE_COEMPRUNTEUR,
      fieldName: 'mensualite_totale_' + role,
      description: getMontant(assuranceSelected?.mensualite_avec_assurance),
      isLoading: getIsLoading(),
    },
  ];

  return (
    <StyledWrapperSummary>
      <StyledCentering>
        <StyledTitle>{messages.LAYOUT_TITLE}</StyledTitle>
      </StyledCentering>
      <StyledDescription>{messages.LAYOUT_DESC}</StyledDescription>
      <SummaryCard
        Icon={<Icons.PersonIcon width={16} height={16} />}
        title={messages.IDENTITE_EMPRUNTEUR_TITLE}
        list={getIdentity(emprunteur, RoleCd.BORROWER)}
      />
      {hasCoborrower() && (
        <SummaryCard
          Icon={<Icons.PersonIcon width={16} height={16} />}
          title={messages.IDENTITE_COEMPRUNTEUR_TITLE}
          list={getIdentity(coemprunteur, RoleCd.COBORROWER)}
        />
      )}
      <SummaryCard
        Icon={<Icons.FinancementIcon />}
        title={messages.OFFRE_FINANCEMENT_TITLE}
        list={offreFinancement}
      />
      <SummaryCard
        texteBadge={
          assuranceEmprunteurSelected?.code_bareme
            ? assuranceEmprunteurSelected?.labelRecap ?? ' '
            : messages.NO_INSURANCE
        }
        legendeBadge={
          assuranceEmprunteurSelected?.code_bareme
            ? ' (' + assuranceEmprunteurSelected?.code + ')'
            : ''
        }
        Icon={<Icons.AssuranceIcon width={22} height={20} />}
        title={messages.ASSURANCE_EMPRUNTEUR_TITLE}
        list={
          assuranceEmprunteurSelected?.code_bareme
            ? getAssurance(assuranceEmprunteurSelected, RoleCd.BORROWER)
            : []
        }
      />
      {hasCoborrower() && (
        <>
          <SummaryCard
            texteBadge={
              assuranceCoemprunteurSelected?.code_bareme
                ? assuranceCoemprunteurSelected?.labelRecap ?? ' '
                : messages.NO_INSURANCE
            }
            legendeBadge={
              assuranceCoemprunteurSelected?.code_bareme
                ? ' (' + assuranceCoemprunteurSelected?.code + ')'
                : ''
            }
            Icon={<Icons.AssuranceIcon width={22} height={20} />}
            title={messages.ASSURANCE_COEMPRUNTEUR_TITLE}
            list={
              assuranceCoemprunteurSelected?.code_bareme
                ? getAssurance(assuranceCoemprunteurSelected, RoleCd.COBORROWER)
                : []
            }
          />
          {!!syntheseAssurances?.coutMensuelAssurances &&
            !!assuranceEmprunteurSelected?.code_bareme &&
            !!assuranceCoemprunteurSelected?.code_bareme && (
              <SummaryCard
                Icon={<Icons.ContractIcon />}
                title={messages.SYNTHESE_ASSURANCES_TITLE}
                list={synthese}
              />
            )}
        </>
      )}

      <Modal
        isVisible={isModalFormationModeActive}
        isClosable
        handleCloseModal={() => setIsModalFormationModeActive(false)}
      >
        <FormationModeScoreChoice handleClick={actionChoseFormationModeScore} />
      </Modal>
      <ButtonsFooter onClickNext={onSubmit} requestsToWait={[resultGetScore]} />
    </StyledWrapperSummary>
  );
};

export default Summary;
